@keyframes scrolled {
  0% {
    top: -70px;
  }

  100% {
    top: 0;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  @media (min-width: 768px) {
    position: absolute;
  }

  .navbar-brand {
    font-weight: bold;
    letter-spacing: 2px;
    color: #4e4e4e;
    padding: 0 !important;

    @media (min-width: 768px) {
      font-size: 1.8rem;
    }

    i {
      color: #ffc824;
      font-size: 0.5rem;
    }
  }
  .navbar-brand img{
    padding: 0 !important;
  }
  .navbar {
    box-shadow: 0px 2px 6px rgba(43, 73, 111, 0.2);

    &.scrolled {
      @media (min-width: 768px) {
        position: fixed;
        left: 0;
        right: 0;
        animation: scrolled 0.3s ease forwards;
        -webkit-animation: scrolled 0.3s ease forwards;
}
    }
  }

  .nav-link {
    text-transform: uppercase;
    font-weight: 700;
    color: #4e4e4e !important;
    font-size: 1.4rem;

    &:hover {
      color: darken(#ffc824, 6%) !important;
    }

    &.active {
      color: lighten(#ffc824, 5%) !important;
    }

    @media (min-width: 768px) {
      padding: 1rem 1rem;
    }
  }

  .top-bar {
    background-color: #222;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .nav-icon {
    width: 30px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #4e4e4e;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        top: 9px;
      }

      &:nth-child(3) {
        top: 18px;
      }
    }

    &.open {
      span {
        &:nth-child(1) {
          top: 9px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }

        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }

        &:nth-child(3) {
          top: 9px;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }
  }
}