$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import './partials/header';
@import './partials/home';
@import './partials/about';
@import './partials/services';
@import './partials/facts';
@import './partials/blog';
@import './partials/footer';

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

body {
  height: 100vh;
  font-family: 'Open Sans';
}

.phone {
  color: #fff !important;
}

.section-header {
  .section-title {
    font-weight: bold;
    color: #4e4e4e;
    font-family: 'Open Sans';
    position: relative;
    margin-bottom: 2rem;

    span {
      color: #ffc824;
    }

    &:after {
      content: '';
      content: '';
      display: block;
      height: 3px;
      width: 70px;
      background: #ffc824;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .section-subtitle {
    max-width: 700px;
    color: #6f6f6f;
    font-size: 0.9rem;
  }
}

ol li {
  position: relative;
}

.circle-list {
  list-style:circle;
}


.testimonial {
  height: 300px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial__nav {
  width: 12px;
  height: 12px;
  margin: 1rem 12px;
  border-radius: 50%;
  z-index: 10;
  outline: 6px solid #ccc;
  outline-offset: -6px;
  box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.testimonial__nav:checked {
  -webkit-animation: check 0.4s linear forwards;
  animation: check 0.4s linear forwards;
}

.testimonial__nav:checked:nth-of-type(1)~.testimonial__inner {
  left: 0%;
}

.testimonial__nav:checked:nth-of-type(2)~.testimonial__inner {
  left: -100%;
}

.testimonial__nav:checked:nth-of-type(3)~.testimonial__inner {
  left: -200%;
}

.testimonial__nav:checked:nth-of-type(4)~.testimonial__inner {
  left: -300%;
}

.testimonial__nav:checked:nth-of-type(5)~.testimonial__inner {
  left: -400%;
}

.testimonial__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 500%;
  height: 100%;
  -webkit-transition: left 0.4s;
  transition: left 0.4s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.testimonial__contents {
  height: 100%;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial__image {
  font-size: 2.7rem;
  color: #2196F3;
}

.testimonial__caption {
  font-weight: 500;
  margin: 2rem 0 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

.testimonial__txt {
  color: #ccc;
  margin-bottom: 2rem;
  min-width: 300px;
  max-width: 600px;
}

@-webkit-keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }

  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}

@keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }

  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}