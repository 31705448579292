
.aboutImage {
  position: relative;
  width: 100%;
  max-height: 300px;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.aboutImage .content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  font-size: 20px !important;
  width: 100%;
  padding: 20px;
}
.doronImage {
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.doronImage img {
  --s: 15px;
  /* size of the frame */
  --b: 2px;
  /* border thickness */
  /*  --w: 200px; width of the image */
  --c: #7B3B3B;

  width: var(--w);
  aspect-ratio: 1;
  object-fit: cover;
  padding: calc(2*var(--s));
  --_g: var(--c) var(--b), #0000 0 calc(100% - var(--b)), var(--c) 0;
  background:
    linear-gradient(var(--_g)) 50%/100% var(--_i, 100%) no-repeat,
    linear-gradient(90deg, var(--_g)) 50%/var(--_i, 100%) 100% no-repeat;
  outline: calc(var(--w)/2) solid #0009;
  outline-offset: calc(var(--w)/-2 - 2*var(--s));
  transition: .4s;
  cursor: pointer;
}

.doronImage img:hover {
  outline: var(--b) solid var(--c);
  outline-offset: calc(var(--s)/-2);
  --_i: calc(100% - 2*var(--s));
}

.about-description {
  font-size: 0.9rem;
  color: #6f6f6f;
}
.about-description{
  font-size: 1rem;
}
